import AbstractService from "./AbstractService";
import Axios from "axios";

export default class CustomerPaymentService extends AbstractService {
    private controller = "customer-payments";
    
    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public current(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/current`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public summary(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/summary`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public submit(d: any) {
        const url = this.makeUrl(`${this.controller}/submit`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public post(customerPayment: any) {
        const d = { customerPayment };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public put(d: any) {
        const url = this.makeUrl(`${this.controller}`);
        return Axios.put<any>(url, d, this.makeConfig());
    }
    
    public finalize(customerPayment: any) {
        const d = { customerPayment };
        const url = this.makeUrl(`${this.controller}/finalize`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public approve(customerPayment: any) {
        const d = { customerPayment };
        const url = this.makeUrl(`${this.controller}/approve`);
        return Axios.put<any>(url, d, this.makeConfig());
    }
    
    public cancel(customerPayment: any) {
        const d = { customerPayment };
        const url = this.makeUrl(`${this.controller}/cancel`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public reject(customerPayment: any) {
        const d = { customerPayment };
        const url = this.makeUrl(`${this.controller}/reject`);
        return Axios.put<any>(url, d, this.makeConfig());
    } 

    public delivered(customerPayment: any) {
        const d = { customerPayment };
        const url = this.makeUrl(`${this.controller}/delivered`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public refunded(customerPayment: any) {
        const d = { customerPayment };
        const url = this.makeUrl(`${this.controller}/refunded`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public receipt(customerPayment: any) {
        const d = { customerPayment };
        const url = this.makeUrl(`${this.controller}/receipt`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public delete(customerPayment: any) {
        const d = { customerPayment };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.delete<any>(url, this.makeConfig(true, d));
    } 
}