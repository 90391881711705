









































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { HomeProperties } from "@/components/home/HomeView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ResponseUtil from "@/utilities/ResponseUtil";
import DateUtil from "@/utilities/DateUtil";
import CompanyService from "@/services/CompanyService";
import CustomerPaymentService from "@/services/CustomerPaymentService";

@Component({
	metaInfo: {
		link: [{ rel: "stylesheet", href: "/home-payment.css" }]
	},
    data: () => ({
        recordCounts: [10, 20, 30, 40, 50],
        pageNo: 1,
        pageCount: 0,
        recordCount: 20,
        dateTimeFormat: null,
        loading: false,
        currency: {},
        payments: [],
    }),
})
export default class PaymentListView extends Vue {
    @Prop() private home: HomeProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private companyService = new CompanyService();
    private customerPaymentService = new CustomerPaymentService();

    public get title() {
        var tmp = this.$t('title.payment');
        if (this.$route.path.startsWith("/processing")) {
            tmp = this.$t('title.processing');
        }
        return tmp;
    }

    public getPaymentUrl(uniqueNo: string) {
        var tmp = "/payment/";
        if (this.$route.path.startsWith("/processing")) {
            tmp = "/processing/";
        }
        return tmp + uniqueNo;
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.$data.dateTimeFormat) : "";
    }

    public formatStatus(v: string) {
        switch (v) {
            case "Processing": return this.$t("text.processing");
            case "Approved": return this.$t("text.approved");
            case "Cancelled": return this.$t("text.cancelled");
            case "Refunded": return this.$t("text.refunded");
            case "Rejected": return this.$t("text.rejected");
            case "Delivered": return this.$t("text.delivered");
            case "Completed": return this.$t("text.completed");
        }
        return v;
    }

	public formatAmount(v: any) {
        const symbol = this.$data.currency.symbol ?? "";
        return symbol + " " + v.toFixed(2);
	}
	
    public async created() {
        await this.loadCurrency();
        await this.load();
    }

    public async loadCurrency() {
        try {
            const r = await this.companyService.currency();
            this.$data.currency = r.data;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async load() {
        const pageNo = this.$data.pageNo;
        const rowCount = this.$data.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.loading = false;

        try {
            var status = null;
            var type = null;

            if (this.$route.path.startsWith("/processing")) {
                status = ["Processing", "Approved"];
                type = ["Checkout"];
            }

            const r = await this.customerPaymentService.current({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                status,
                type
            });
            this.$data.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);

            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;
            const customerPayments: any[] = r.data.customerPayments;

			customerPayments.forEach(e => {
        		var v = e.receiptPayment ?? 0;
        		v += e.walletPayment ?? 0;
				v += e.cashPayment ?? 0;
				e.paymentAmount = v;
			});

            this.$data.payments = customerPayments.sort((lhs, rhs) => rhs.id - lhs.id);
            await Vue.nextTick();
            this.$data.pageCount = count;
            if (pageNo > count) {
                this.$data.pageNo = count;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.loading = false;
        }
    }
}
